import * as React from "react"

function YoutubeIcon(props) {
  return (
    <svg
      width={25}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block"
    >
      <path
        d="M23.5 2.512a2.786 2.786 0 00-.765-1.27A3.05 3.05 0 0021.397.5C19.519.005 11.995.005 11.995.005a76.624 76.624 0 00-9.39.47c-.502.14-.962.4-1.338.76-.37.356-.638.795-.778 1.276A29.09 29.09 0 000 8.005c-.012 1.841.15 3.68.488 5.494.137.479.404.916.775 1.269.37.353.833.608 1.34.743 1.904.494 9.39.494 9.39.494 3.141.036 6.281-.121 9.403-.47a3.05 3.05 0 001.338-.742 2.78 2.78 0 00.765-1.27c.345-1.811.512-3.651.5-5.495.027-1.851-.141-3.7-.5-5.517v.001zM9.604 11.429V4.582l6.26 3.424-6.26 3.423z"
        fill="currentColor"
      />
    </svg>
  )
}

export default YoutubeIcon
