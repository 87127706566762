import React from "react"
import { Link } from "gatsby"

const HyperLink = ({ text, url, isExternal }) => {
  const linkStyle = `inline-block px-2 leading-[3px] uppercase tracking-[1px] font-medium text-p12 border-b-4 border-dd-black-30 `
  const linkToShow = isExternal ? (
    <a
      href={url}
      className={linkStyle}
      target="_blank"
      rel="nofollow noreferrer"
    >
      {text}
    </a>
  ) : (
    <Link to={url} className={linkStyle}>
      {text}
    </Link>
  )
  return linkToShow
}

export default HyperLink
