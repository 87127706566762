import React from "react"
import ctl from "@netlify/classnames-template-literals"

const MenuIntro = () => {
  const menu_intro_wrapper_style = ctl(`
  text-white
  pt-20 
  flex-shrink-0 
  lg:block 
  hidden 
  flex-grow-0 
  w-[309px]
  `)
  return (
    <section className={menu_intro_wrapper_style}>
      <h2 className='text-[45px]'>
        For <br/> designers, <br/> by designers.
      </h2>
      <p className="text-p14 mt-7">
        At DearDesigner, we are telling the stories of designers across Africa.
        <br/> <br/>
       With a well-curated content experience, hands-on mentorship, partnerships, educational programmes; we aim to give design the status it deserves in value, practice, and influence in everyday decisions—in Africa.
      </p>
    </section>
  )
}

export default MenuIntro
