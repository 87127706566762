import React from "react"
import { EmailIcon, FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon, YoutubeIcon } from "../../icons/social"

const social_media_links = [
  {
    icon: <EmailIcon />,
    link: `mailto=hello@deardesigner.xyz`
  },
  {
    icon: <InstagramIcon />,
    link: `https://www.instagram.com/deardesignr/`
  },
  {
    icon: <TwitterIcon />,
    link: `https://twitter.com/DearDesignr`
  },
  {
    icon: <YoutubeIcon />,
    link: `https://www.youtube.com/channel/UCGJvj1AinS3w-A3nAb55yYA`
  },
  {
    icon: <LinkedinIcon />,
    link: `https://www.linkedin.com/company/deardesignr/`
  },
  {
    icon: <FacebookIcon />,
    link: `https://www.facebook.com/deardesignr`
  }
]
export const showSocialMediaIcons = social_media_links.map((social, index) => (
  <a
    href={social.link}
    target="_blank"
    rel="noreferrer"
    className="inline-flex items-center"
    key={index}
  >
    {social.icon}
  </a>
))