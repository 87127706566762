import * as React from "react"

function TwitterIcon(props) {
  return (
    <svg
      width={19}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block"
    >
      <path
        d="M18.697 1.836c-.7.315-1.443.523-2.203.617A3.898 3.898 0 0018.18.286a7.593 7.593 0 01-2.436.948A3.817 3.817 0 0013.567.051a3.773 3.773 0 00-2.433.413A3.887 3.887 0 009.45 2.302a3.992 3.992 0 00-.242 2.504 10.718 10.718 0 01-4.379-1.185A10.934 10.934 0 011.301.719c-.49.862-.64 1.881-.42 2.852.22.97.794 1.818 1.605 2.372a3.77 3.77 0 01-1.735-.49c-.011.912.29 1.8.851 2.51A3.827 3.827 0 003.825 9.34a3.807 3.807 0 01-1.73.068 3.911 3.911 0 001.364 1.945 3.785 3.785 0 002.219.77A7.606 7.606 0 010 13.748a10.692 10.692 0 005.878 1.758c7.122 0 11.145-6.133 10.901-11.64a7.858 7.858 0 001.918-2.029z"
        fill="currentColor"
      />
    </svg>
  )
}

export default TwitterIcon
