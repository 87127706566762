import React from "react"
import ctl from "@netlify/classnames-template-literals"
import { HyperLink } from "../../../utils"
import { Link } from "@reach/router"

const ColumnMenuLink = ({ items, columnLink }) => {
  const mostRecentItem = items[0]

  const columnMenuLinkStyle = ctl(
    `hidden group-hover:block text-dd-black-70 py-4 md:pl-5`
  )

  return (
    <span className={columnMenuLinkStyle}>
      <Link to={`/${mostRecentItem.slug}`}>{mostRecentItem.title}</Link>
      <br />
      <HyperLink text="Read Column" url={columnLink} />
    </span>
  )
}
export default ColumnMenuLink
