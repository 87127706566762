import React from "react"
import MenuNavHeader from "../nav/header"
import MenuNavLinks from "../nav/links"
import { graphql, useStaticQuery } from "gatsby"

const MenuItemBlog = () => {
  const columnsQuery = useStaticQuery(graphql`
    {
      allWpCategory(filter: { slug: { nin: ["uncategorized", "events"] } }) {
        nodes {
          name
          slug
        }
      }
    }
  `)

  const columns = columnsQuery.allWpCategory.nodes
  return (
    <div className="sm:row-span-2 row-span-1 ">
      <MenuNavHeader className='sm-max:text-dd-black-50' text="Blog" url="/blog" />
      <MenuNavLinks links={columns} sidebar />
    </div>
  )
}

export default MenuItemBlog
