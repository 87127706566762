import React from "react"
import SuccessIcon from "../../../icons/success"

const FormSuccess = ({ message }) => {
  return (
    <div className='p-2 pr-[14px] bg-green-light bg-opacity-6 border border-green-dark text-left w-[fit-content]'>
      <SuccessIcon className='mr-2 inline-block'/>
      <span className='text-dd-black-60 text-xs leading-[18px]'>
        {message}
      </span>
    </div>
  )
}

export default FormSuccess