import React from "react"
import MenuNavHeader from "../nav/header"
import MenuNavLinks from "../nav/links"
import { graphql, useStaticQuery } from "gatsby"

const MenuItemMentorship = ({ showSubLinks }) => {
  const MENU_ITEM_URL = "mentorship"
  const editionsQuery = useStaticQuery(graphql`
    {
      allWpMentorshipEdition(
        filter: { customFields: { status: { eq: "done" } } }
      ) {
        nodes {
          name
          slug
        }
      }
    }
  `)

  const editions = editionsQuery.allWpMentorshipEdition.nodes

  const theEditions = editions.map(edition => {
    return {
      name: `#DDMentorship ${edition.name}`,
      slug: `${MENU_ITEM_URL}/${edition.slug}`,
    }
  })

  theEditions.unshift({
    name: "About",
    slug: "mentorship",
  })
  return (
    <div className="">
      <MenuNavHeader text="Mentorship" url={`/${MENU_ITEM_URL}`} />
      {showSubLinks && <MenuNavLinks sidebar links={theEditions} />}
    </div>
  )
}

export default MenuItemMentorship
