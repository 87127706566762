import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FooterMenu from "./menu"
import JoinOurCommunity from "../../utils/join-our-community"
import { showSocialMediaIcons } from "../../utils"
import ctl from "@netlify/classnames-template-literals"

const MainFooter = () => {
  return (
    <footer className='flex px-6 lg:pl-[27%] lg:pr-[90px] lg:items-start flex-col pb-16 md:pb-60'>
      <div className='w-[50px] flex-shrink-0 md:hidden mb-12 pl-1'>
        <StaticImage
          src="../../../images/icons_logos/DearDesigner_Icon.png"
          placeholder="blurred"
          alt="DearDesigner Icon"
        />
      </div>
      <section className='max-w-[250px] md:max-w-[500px] mb-[110px] md:mb-[60px]'>
        <JoinOurCommunity location_footer />
      </section>
      <section className='flex gap-x-[168px] md-max:flex-col gap-y-[88px]'>
        <div className='md:max-w-[500px] flex gap-x-[68px]'>
          <div className='w-10 flex-shrink-0 md-max:hidden'>
            <StaticImage
              src="../../../images/icons_logos/DearDesigner_Icon.png"
              placeholder="blurred"
              alt="DearDesigner Icon"
            />
          </div>
          <FooterMenu/>
        </div>
        <div className={footerStyle}>
          {showSocialMediaIcons}
        </div>
      </section>
    </footer>
  )
}

const footerStyle = ctl(`
  flex md:grid 
  md-max:justify-center md:grid-cols-[24px,16px,18px] 
  gap-y-2 gap-x-[30px] md:gap-x-4 
  h-[fit-content] text-dd-black-30
`)

export default MainFooter
