import React from "react"

const SearchIcon = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.69035 10.8502C8.4361 10.8502 10.662 8.6243 10.662 5.87856C10.662 3.13282 8.4361 0.906952 5.69035 0.906952C2.94461 0.906952 0.71875 3.13282 0.71875 5.87856C0.71875 8.6243 2.94461 10.8502 5.69035 10.8502Z" stroke="#494949" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.9057 12.0931L9.20239 9.38974" stroke="#494949" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SearchIcon