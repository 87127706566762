import React from "react"
import { Link } from "gatsby"
import ctl from "@netlify/classnames-template-literals"

const MenuNavHeader = ({ text, url, sidebar, className = '' }) => {
  const heading_style = ctl(`
    text-p14 
    font-bold 
    tracking-widest 
    uppercase 
    leading-tight
    sm:mb-6
    text-black
    ${className}
    `)

  return (
    <h3 className={heading_style}>
      <Link to={url}>{text}</Link>
    </h3>
  )
}

export default MenuNavHeader
