import React from "react"
import ErrorIcon from "../../../icons/error"

const FormError = ({ message }) => {
  return (
    <div className='p-2 pr-[14px] bg-red-light bg-opacity-6 border border-red-dark w-[fit-content] text-left'>
      <ErrorIcon className='mr-2 inline-block'/>
      <span className='text-dd-black-60 text-xs leading-[18px]'>
        {message}
      </span>
    </div>
  )
}

export default FormError