import { useState } from "react"
import { FORM_STATES } from "../utils/constants"
import { submitForm } from "../api/form"

const useForm = () => {
  const [formState, setFormState] = useState(FORM_STATES.IDLE)
  const [errorMessage, setErrorMessage] = useState(null)

  const onSubmit = async (e) => {
    e.preventDefault();
    const {target} = e;
    const formData = new FormData(target);

    const queryParams = new URLSearchParams();

    for (let pair of formData.entries()){
      queryParams.set(pair[0], pair[1])
    }

    try{
      setFormState(FORM_STATES.LOADING)
      await submitForm(queryParams.toString())
      setFormState(FORM_STATES.SUCCESS)
    }catch (e) {
      setErrorMessage(e.message)
      setFormState(FORM_STATES.ERROR)
    }
  }

  return {
    isLoading: formState === FORM_STATES.LOADING,
    isIdle: formState === FORM_STATES.IDLE,
    isSuccess: formState === FORM_STATES.SUCCESS,
    isError: formState === FORM_STATES.ERROR,
    errorMessage,
    setErrorMessage,
    setFormState,
    onSubmit
  }
}

export default useForm