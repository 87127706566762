import axios from "axios"

export const submitForm = async (data) => {
  await axios.post(
    '/',
    data,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }
  )
}