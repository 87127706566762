import React from "react"
import { Link } from "gatsby"
import ctl from "@netlify/classnames-template-literals"
import ColumnMenuLink from "./column-link"

const MenuNavLinks = ({ links = [], sidebar }) => {
  const menu_list_style = ctl(`
  sm:grid
  gap-y-[15px]
  hidden 
  `)
  const showLinks = links.map((link, index) => {
    const postsInLink = link.posts?.nodes

    const postsInLinkLength = postsInLink?.length

    const hasChildren = postsInLinkLength > 0

    const linkTextStyle = `text-dd-black-60 ${sidebar && 'hover:text-dd-black-80'}`

    return (
      <li
        className={`text-dd-black-60 leading-tight group transition-all ${sidebar && 'hover:text-dd-black-80'}`}
        key={index}
      >
        {
          hasChildren ? (
            <span
              key={index}
              className={linkTextStyle}
            >
              {link.name}
            </span>
          ) : (
            <Link
              to={`/${link.slug}`}
              key={index}
              className={linkTextStyle}
            >
              {link.name}
            </Link>
          )
        }

        {hasChildren ? (
          <ColumnMenuLink items={postsInLink} columnLink={`/${link.slug}`} />
        ) : null}
      </li>
    )
  })

  return <ol className={menu_list_style}>{showLinks}</ol>
}

export default MenuNavLinks
