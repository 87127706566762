import React from "react"
import { Link } from "gatsby"

const getMatchedHTML = (text, search) => {
  const searchRegex = new RegExp(search, 'i')

  if(search && searchRegex.test(text)){
    const [matchedSection] = text.match(searchRegex);
    return text.replace(searchRegex, `<span class="match">${matchedSection}</span>`)
  }

  return text
}

const Result = ({ title, uri, date, author, searchValue }) => {
  return (
    <div className='search-result'>
      <Link to={uri}>
        <h5
          className='text-[12px] leading-[18px] text-dd-black-70 mb-1 font-normal'
          dangerouslySetInnerHTML={{__html: getMatchedHTML(title, searchValue)}}
        >
        </h5>
      </Link>
      <p
        className='text-[8px] leading-[12px] text-dd-black-70 mt-0 whitespace-pre'
        dangerouslySetInnerHTML={{__html: `${date}   |   ${getMatchedHTML(author || "DearDesigner", searchValue)}`}}
      >
      </p>
    </div>
  )
}

export default Result