import React from "react"
import { Link } from "gatsby"

const desktopMenu = [
  [
    {
      name: "About Us",
      url: "/about-us",
      order_mobile: "order-1",
    },
    {
      name: "Contact Us",
      url: "/contact-us",
      order_mobile: "order-3",
    },
    {
      name: "Donate",
      url: "/donate",
      order_mobile: "order-5",
    },
  ],
  [
    {
      name: "Join the team",
      // url: "/join-the-team",
      order_mobile: "order-2",
      disabled: true
    },
    {
      name: "Submit an Article",
      url: "/submit-an-article",
      order_mobile: "order-4",
    },
    {
      name: "Join as Columnist",
      url: "/join-as-a-columnist",
      order_mobile: "order-6",
    },
  ],
  [
    {
      name: "Terms",
      // url: "/",
      order_mobile: "order-7",
      disabled: true
    },
    {
      name: "Privacy",
      // url: "/",
      order_mobile: "order-9",
      disabled: true
    },
    {
      name: "Press Kit",
      // url: "/press-kit",
      order_mobile: "order-8",
      disabled: true
    },
  ]
]

const mobileMenu = [
  [
    {
      name: "About Us",
      url: "/about-us",
      order_mobile: "order-1",
    },
    {
      name: "Contact Us",
      url: "/contact-us",
      order_mobile: "order-3",
    },
    {
      name: "Donate",
      url: "/donate",
      order_mobile: "order-5",
    },
    {
      name: "Terms",
      url: "/",
      // order_mobile: "order-7",
      disabled: true
    },
    {
      name: "Privacy",
      url: "/",
      // order_mobile: "order-9",
      disabled: true
    },
  ],
  [
    {
      name: "Join the team",
      // url: "/join-the-team",
      order_mobile: "order-2",
      disabled: true
    },
    {
      name: "Submit an Article",
      url: "/submit-an-article",
      order_mobile: "order-4",
    },
    {
      name: "Join as Columnist",
      url: "/join-as-a-columnist",
      order_mobile: "order-6",
    },
    {
      name: "Press Kit",
      // url: "/press-kit",
      order_mobile: "order-8",
      disabled: true
    },
  ]
]

const FooterMenu = () => {
  return (
    <div className='flex md:gap-x-[74px] gap-x-[94px]'>
      {
        desktopMenu.map(column => (
          <ol className='grid gap-y-3 h-[fit-content] md-max:hidden'>
            {
              column.map(link => (
                <li className={`text-dd-black-50 text-sm leading-[21px] ${link.disabled ? 'opacity-30 cursor-not-allowed' : ''}`}>
                  {
                    link.disabled ? (
                      <span key={`footer link ${link.name}`}>
                        {link.name}
                      </span>
                    ) : (
                      <Link to={link.url} key={`footer link ${link.name}`}>
                        {link.name}
                      </Link>
                    )
                  }
                </li>
              ))
            }
          </ol>
        ))
      }
      {
        mobileMenu.map(column => (
          <ol className='grid gap-y-3 h-[fit-content] md:hidden'>
            {
              column.map(link => (
                <li className={`text-dd-black-50 text-sm leading-[21px] ${link.disabled ? 'opacity-30 cursor-not-allowed' : ''}`}>
                  {
                    link.disabled ? (
                      <span key={`footer link mobile ${link.name}`}>
                        {link.name}
                      </span>
                    ) : (
                      <Link to={link.url} key={`footer link mobile ${link.name}`}>
                        {link.name}
                      </Link>
                    )
                  }
                </li>
              ))
            }
          </ol>
        ))
      }
    </div>
  )
}

export default FooterMenu
