import React from "react"
import MenuNavHeader from "../nav/header"
import MenuNavLinks from "../nav/links"

const MenuItemAboutUs = ({ showSubLinks }) => {
  const MENU_ITEM_URL = "about-us"
  const sections = [
    { name: "Mission", slug: `${MENU_ITEM_URL}#mission` },
    { name: "People", slug: `${MENU_ITEM_URL}#people` },
    { name: "Partners", slug: `${MENU_ITEM_URL}#partners` },
  ]

  return (
    <div className="group">
      <MenuNavHeader text="About Us" url={`/${MENU_ITEM_URL}`} />
      {showSubLinks && <MenuNavLinks sidebar links={sections} />}
    </div>
  )
}

export default MenuItemAboutUs
