import React from "react"
import MainMenu from "../menu"

const MainHeader = ({ isHome }) => {
  return (
    <header>
      <MainMenu isHome={isHome} />
    </header>
  )
}

MainHeader.propTypes = {}

export default MainHeader
