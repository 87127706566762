import React from "react"

const SuccessIcon = ({...props}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.50182 13.475L4.61016 10.5833C4.28516 10.2583 3.76016 10.2583 3.43516 10.5833C3.11016 10.9083 3.11016 11.4333 3.43516 11.7583L6.91849 15.2417C7.24349 15.5667 7.76849 15.5667 8.09349 15.2417L16.9102 6.42501C17.2352 6.10001 17.2352 5.57501 16.9102 5.25001C16.5852 4.92501 16.0602 4.92501 15.7352 5.25001L7.50182 13.475Z" fill="#5ADC5F"/>
    </svg>
  )
}

export default SuccessIcon