import React, { useState } from "react"
import SearchIcon from "../../../icons/search"
import ctl from "@netlify/classnames-template-literals"
import Result from "./result"
import "./search.css"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"

const SearchInput = () => {
  const [searchValue, setSearchValue] = useState('');
  const { localSearchPosts: { index, store } } = useStaticQuery(graphql`
    query SearchQuery {
       localSearchPosts{
         index
         store
       }
    }
  `)

  const results = useFlexSearch(searchValue, index, store)

  const onKeyPress = (e) => {
    if(e.key === 'Enter'){
      setSearchValue('')
      return navigate(`/search?query=${searchValue}`)
    }
  }

  const search_wrapper_style = ctl(` 
    w-[233px]
    flex
    border-b border-dd-black-50
    ml-auto 
    focus-within:border-dd-black 
    duration-300
    md-max:hidden
    relative
  `)

  const search_results_wrapper = ctl(`
     w-[262px] absolute top-[100%]
     transform translate-y-4 
     bg-white shadow-search rounded-[5px]
     pt-5 pl-5 pr-3 pb-[28px] duration-300
     ${searchValue ? 'opacity-1' : 'translate-y-8 opacity-0'}
  `)

  return (
    <div className={search_wrapper_style}>
      <input
        name="search"
        id="search"
        placeholder="Search"
        required="required"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        className='border-0'
        onKeyPress={onKeyPress}
        autoComplete="off"
      />
      <button
        type="submit"
        className='block w-6'
      >
        <SearchIcon />
      </button>
      <div className={search_results_wrapper}>
        <div className='pr-[28px] overflow-y-auto max-h-[245px] search-results'>
          {
            results.map(result => (
              <>
                <Result
                  {...result}
                  searchValue={searchValue}
                />
                <span className='h-[1px] w-full bg-dd-black-30 block my-[14px] last:hidden'/>
              </>
            ))
          }
          {(results.length === 0) && <p className='text-center m-0'>No results found</p>}
        </div>
      </div>
    </div>
  )
}

export default SearchInput