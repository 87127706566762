import React from "react"
import DearDesignerLogo from "../../../icons/deardesigner"
import ctl from "@netlify/classnames-template-literals"
import HamburgerIcon from "../../../icons/hamburger"
import CloseIcon from "../../../icons/close"
import SearchInput from "../../utils/search/search-input"

const Navbar = ({ light = false, menuIsOpen, toggleMenu, isHome }) => {
  const navbarStyle = ctl(`
    wrapper
    flex
    ${isHome ? 'justify-between' : ''}
    py-11
    pb-4
    sm:pb-11
    items-center
    w-full
    `)

  const renderSideItems = () => {
    if(isHome){
      if(menuIsOpen){
        return (
          <button
            onClick={toggleMenu}
            className="md:w-auto w-9 md:h-16 h-auto  block"
          >
            <CloseIcon />
          </button>
        )
      }

      return (
        <button
          onClick={toggleMenu}
          className="md:w-auto w-9 md:h-16 h-auto  block"
        >
          <HamburgerIcon />
        </button>
      )
    }

    return (
      <>
        <button
          onClick={toggleMenu}
          className="md:hidden w-9 h-auto block ml-auto"
        >
          {menuIsOpen ? <CloseIcon/> : <HamburgerIcon />}
        </button>
        <SearchInput/>
        <div className='w-2/12 ml-12 md-max:hidden'/>
      </>
    )
  }
  return (
    <header className={navbarStyle}>
      <DearDesignerLogo light={light} />
      {renderSideItems()}
    </header>
  )
}

export default Navbar
