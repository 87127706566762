import React, { useState } from "react"
import ctl from "@netlify/classnames-template-literals"
import Navbar from "../navbar"
import MenuIntro from "./intro"
import ConnectWithUs from "./connect-with-us"
import { MenuItemAboutUs, MenuItemBlog, MenuItemDonate, MenuItemMentorship } from "./items"

import JoinOurCommunity from "../../utils/join-our-community"
import MenuNavHeader from "./nav/header"

const MainMenu = ({ isHome }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const mainMenuWrapperStyle = ctl(
    `
    w-full
    h-screen
    bg-black
    fixed
    top-0
    left-0
    z-[100]
    flex flex-col
    `
  )

  const navContainer = ctl(
    `
    ${menuIsOpen ? "flex" : "hidden"}
    sm:wrapper 
    pl-8     
    sm:pb-[10vh]
    between 
    items-start
    z-[100]
    flex-1
    gap-x-9
  `
  )

  const grid = ctl(`
    grid 
    sm:grid-cols-3 
    grid-cols-1 
    grid-rows-2
    md:gap-y-8 sm:gap-x-[40px] xl:gap-x-[110px] 
    gap-y-[34px]
    gap-0
  `)

  const navStyle = ctl(`
  bg-white 
  xl:pl-28 md:pt-20 xl:pr-[110px] md:pb-11
  md:px-16
  p-8
  md-max:pb-20 
  text-left 
  flex-shrink flex-1 flex flex-col
  h-full
  `)

  return (
    <section className={menuIsOpen ? mainMenuWrapperStyle : ""}>
      <Navbar
        light={menuIsOpen}
        menuIsOpen={menuIsOpen}
        toggleMenu={() => {
          setMenuIsOpen(menuIsOpen => !menuIsOpen)
        }}
        isHome={isHome}
      />
      <div className={navContainer}>
        <MenuIntro />
        <nav className={navStyle}>
          <section className={grid}>
            <MenuItemBlog />
            <MenuItemMentorship showSubLinks/>
            <MenuItemAboutUs showSubLinks />
            {/*<MenuItemIntitiatives />*/}
            <div className='sm:hidden my-5 space-y-[40px]'>
              <MenuItemDonate />
              <MenuNavHeader text="Contact Us" url={`/contact-us`} />
            </div>
            <JoinOurCommunity location_menu />
            <div className='sm-max:hidden'>
              <MenuItemDonate />
              <MenuNavHeader text="Contact Us" url={`/contact-us`} />
            </div>
          </section>
          <ConnectWithUs />
        </nav>
      </div>
    </section>
  )
}

MainMenu.propTypes = {}

export default MainMenu
