import * as React from "react"
import PropTypes from "prop-types"
import Seo from "./seo"
import MainFooter from "./footer"
import MainHeader from "./header"

const Layout = ({ children, isHome = false, title, ignoreSiteName = false }) => {
  return (
    <section className="relative">
      <MainHeader isHome={isHome}/>
      <Seo title={title} ignoreSiteName={ignoreSiteName} />

      <main>{children}</main>
      <MainFooter />
    </section>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Layout
