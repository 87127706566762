import React, { useState } from "react"
import ArrowRightIcon from "../../../icons/arrow-right"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ctl from "@netlify/classnames-template-literals"
import { FORM_STATES } from "../../../utils/constants"
import useForm from "../../../hooks/useForm"
import FormSuccess from "../form/success"
import FormError from "../form/error"

const JoinOurCommunity = props => {
  const heading_style = ctl(`
  text-p14 
  font-bold 
  tracking-widest 
  uppercase  
  ${props.location_sidebar && "mb-3"}
  ${props.location_footer && "mb-10"}
  ${props.location_menu && "mb-6 text-dd-black"} 
  ${!(props.location_sidebar || props.location_menu || props.location_footer) && 'mb-[60px]'}
  leading-tight
  ${
    props.location_footer || props.location_sidebar
      ? " text-dd-black-60"
      : " text-dd-black-70 "
  }
  ${props.heading_center ? " text-center" : "text-left"}
  `)

  const form_style = ctl(`
  grid 
  ${props.location_sidebar ? "grid-cols-1" : "grid-cols-3"}
  ${props.heading_center && "gap-y-10"}
  gap-4
  `)

  const firstname_wrapper_style = ctl(`
  md:col-span-1 
  col-span-full 
  ${props.location_menu && "!col-span-2"}
  ${props.location_footer && 'md-max:max-w-[164px]'}
  ${props.heading_center && "md-max:max-w-[164px] md-max:mx-auto"}
  `)

  const email_wrapper_style = ctl(`
  md:col-span-2 
  col-span-full 
  ${props.location_menu && "md-max:max-w-[250px] md:col-span-full col-span-full"}
  flex focus-within:border-dd-black 
  duration-300
  ${props.heading_center && "md-max:max-w-[246px] md-max:mx-auto md-max:w-full"}
  `)

  const [email, setEmail] = useState("")
  const [firstname, setFirstname] = useState("")
  const { isLoading, isError, isSuccess, setFormState, setErrorMessage, errorMessage } = useForm();

  const addSubscriber = e => {
    e.preventDefault()
    setFormState(FORM_STATES.LOADING)
    addToMailchimp(email, {
      FNAME: firstname,
    }).then(res => {
      if (res.result !== "error") {
        setFirstname("")
        setEmail("")
        setFormState(FORM_STATES.SUCCESS)
      } else {
        setErrorMessage("There was an error adding you the mailing list. Kindly try again")
        setFormState(FORM_STATES.ERROR)
      }
    })
  }
  return (
    <section>
      <h3 className={heading_style}>
        Join Our <br className={!props.location_sidebar ? "hidden" : ''} />
        Community
      </h3>
      <form className={form_style} onSubmit={addSubscriber}>
        <div className={firstname_wrapper_style}>
          <input
            type="text"
            name="firstname"
            id="firstname"
            placeholder="First Name"
            required="required"
            value={firstname}
            onChange={e => setFirstname(e.target.value)}
            className={props.heading_center && "md-max:text-center"}
          />
        </div>
        <div className={`${email_wrapper_style} border-b border-dd-black-40`}>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email Address"
            required="required"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className={`border-0 ${props.heading_center && "md-max:text-center"}`}
          />
          <button
            type="submit"
            className={`block w-6 ${props.heading_center && "md-max:hidden"} ${isLoading ? 'text-dd-black-40' : 'text-dd-black-70'}`}
            disabled={isLoading}
          >
            <ArrowRightIcon />
          </button>
        </div>
        {
          props.heading_center && (
            <button
              type="submit"
              className={`block w-6 col-span-full mx-auto md:hidden ${isLoading ? 'text-dd-black-40' : 'text-dd-black-70'}`}
              disabled={isLoading}
            >
              <ArrowRightIcon />
            </button>
          )
        }
      </form>
      {
        (isSuccess || isError) && (
          <div className='mt-5'>
            {isSuccess && <FormSuccess message="Your subscription was successful"/>}
            {isError && <FormError message={errorMessage}/>}
          </div>
        )
      }
    </section>
  )
}

export default JoinOurCommunity
