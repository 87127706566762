import * as React from "react"

function LinkedinIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block"
    >
      <path
        d="M0 14.702V.947A.72.72 0 00.042.836.927.927 0 01.836.042.722.722 0 00.947 0h13.755a.419.419 0 00.08.038.967.967 0 01.819 1.017c0 4.503-.001 9.007-.004 13.511 0 .168-.037.333-.108.484a.958.958 0 01-.958.55H1.021a.969.969 0 01-.986-.818.264.264 0 00-.035-.08zm8.312-2.493V8.5a2.842 2.842 0 01.032-.456.725.725 0 01.585-.644c.182-.034.369-.043.554-.028a.742.742 0 01.684.56c.041.157.062.319.062.481.004 1.201.004 2.4.004 3.603v.198h1.97c.004-.055.011-.097.011-.143 0-1.322.014-2.645-.01-3.967a6.04 6.04 0 00-.189-1.32 1.687 1.687 0 00-1.407-1.368 4.136 4.136 0 00-1.017-.042c-.547.042-1.062.177-1.291.775v-.775H6.339v6.84c.657-.005 1.305-.005 1.973-.005zm-2.943 0V5.37H3.413v6.84h1.956zM4.4 2.915a1.006 1.006 0 00-1.007.979 1.042 1.042 0 00.986 1.009 1.021 1.021 0 001.01-.996.98.98 0 00-.99-.992z"
        fill="currentColor"
      />
    </svg>
  )
}

export default LinkedinIcon
