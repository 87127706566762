import * as React from "react"

function ArrowRightIcon(props) {
  return (
    <svg
      width={22}
      height={17}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.62 1l7.859 7.86-7.86 7.86M0 8.812h21.523"
        stroke="currentColor"
        strokeWidth={0.5}
      />
    </svg>
  )
}

export default ArrowRightIcon
