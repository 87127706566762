import * as React from "react"

function CloseIcon(props) {
  return (
    <svg
      width={69}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 69 69"
      className="md:w-auto w-9 "
    >
      <path
        stroke="#fff"
        strokeWidth={3}
        d="M18.122 16.001l34.124 34.124M16.001 50.125l34.124-34.124"
      />
    </svg>
  )
}

export default CloseIcon
