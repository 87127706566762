import React from "react"

const PageHeading = ({ text }) => {
  return (
    <h3 className="max-w-xl md:w-full w-10/12 block mt-12 sm:h3 h4 text-dd-black-60">
      {text}
    </h3>
  )
}

export default PageHeading
