import React from "react"
import { showSocialMediaIcons } from "../../../utils"

const ConnectWithUs = () => {
  return (
    <>
      <div className="flex justify-center gap-x-[30px] sm:gap-x-4 mt-auto text-dd-black-60">
        {showSocialMediaIcons}
      </div>
    </>
  )
}

ConnectWithUs.propTypes = {}

export default ConnectWithUs
