import * as React from "react"

function HamburgerIcon(props) {
  return (
    <svg
      width={43}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 27"
      className="md:w-auto w-9 "
    >
      <path stroke="#929292" d="M0 .5h43M0 12.5h43M0 24.5h35" />
      <path fill="#929292" stroke="#929292" d="M38.5 22.5h4v4h-4z" />
    </svg>
  )
}

export default HamburgerIcon
