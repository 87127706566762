import React from "react"
import MenuNavHeader from "../nav/header"

const MenuItemDonate = () => {
  const MENU_ITEM_URL = "donate"
  return (
    <div>
      <MenuNavHeader text="Donate" url={`/${MENU_ITEM_URL}`} />
    </div>
  )
}

export default MenuItemDonate
