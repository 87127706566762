import React from "react"

const ErrorIcon = ({...props}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.0138 10.1282C10.7018 10.1282 11.2778 9.55223 11.2778 8.86428L11.2779 2.86428C11.2779 2.17634 10.7019 1.60034 10.0139 1.60034C9.326 1.60034 8.75 2.17634 8.75 2.86428V8.86428C8.74989 9.56831 9.32589 10.1283 10.0138 10.1283L10.0138 10.1282Z" fill="#DC895A"/>
      <path d="M10.0142 14.5607C10.7024 14.5607 11.2785 13.9848 11.2785 13.297V12.3532C11.2785 11.6654 10.7024 11.0895 10.0142 11.0895C9.32614 11.0895 8.75 11.6654 8.75 12.3532V13.297C8.75 13.9848 9.32614 14.5607 10.0142 14.5607Z" fill="#DC895A"/>
    </svg>
  )
}

export default ErrorIcon